import NP from "number-precision";

export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 240,
  },
  {
    title: "英文名称",
    dataIndex: "material_english_name",
    width: 240,
  },
  {
    title: "产品图片",
    dataIndex: "material_image",
    width: 120,
    scopedSlots: { customRender: "material_image" },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "在库位量",
    dataIndex: "stock_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: "库存总量",
    dataIndex: "quantity",
    width: 100,
    sorter: true,
    ellipsis: true,
  },
  {
    title: "不良品数量",
    dataIndex: "unqualified_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: "库存单位",
    dataIndex: "material_unit",
    width: 100,
    ellipsis: true,
  },
  {
    title: "销售价格",
    dataIndex: "sales_unit_price",
    width: 100,
  },
  {
    title: "产品单价",
    dataIndex: "material__unit_price",
    width: 120,
    ellipsis: true,
    sorter: true,
    customRender: (value, item) => item.material_unit_price,
  },
  {
    title: "总毛重(吨)",
    dataIndex: "material_weight",
    width: 100,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_weight) {
        return NP.times(item.quantity, item.material_weight, 0.001);
      }
    },
  },
  {
    title: "总净重(吨)",
    dataIndex: "material_net_weight",
    width: 100,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_net_weight) {
        return NP.times(item.quantity, item.material_net_weight, 0.001);
      }
    },
  },
  {
    title: "总体积(立方)",
    dataIndex: "material_volume",
    width: 100,
    ellipsis: true,
    customRender: (value, item) => {
      if (item.material_volume) {
        return NP.times(item.quantity, item.material_volume);
      }
    },
  },
];
